import * as React from 'react';
import { CSSProperties } from 'react';
import styled from 'styled-components';
import { MunikumIcons } from '../common/icons';
import moment from 'moment';
import Mutation from 'react-apollo/Mutation';
import { Colors, safeInvoke } from '../common';
import {
  ContentStatus,
  FieldsOnMiniPersonFragment,
  UpdateEventInstanceInput,
  UpdateInstanceStatusVariables,
} from '../../models/types';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { CheckboxLight } from '../basic/Checkbox/CheckboxLight';
import { Avatar } from '../person/Avatar';
import { Tooltip } from '../basic/Tooltip/Tooltip';
import { commonLabels } from '../language/commonLabels';
import { commonMessages } from '../language/commonMessages';
import { convertFromRaw } from 'draft-js';
import { UserPreview } from '../basic/UserPreview/UserPreview';
import _ from 'lodash';
import { UPDATE_INSTANCE_STATUS } from './TaskComp';

const TaskDiv = styled.div`
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  height: 100%;
  overflow: hidden;
  // white-space: nowrap;
  //text-overflow: ellipsis;
`;

const TaskDate = styled.div`
  color: ${props => props.theme.textColor};
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 12em;
`;

const AssignedToDiv = styled.div`
  color: ${props => props.theme.textColor};
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 8em;
`;

const DaysRemainingDiv = styled.div`
  color: ${props => props.theme.textColor};
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 10px;
  width: 9em;
`;

const TaskText = styled.div`
  color: ${props => props.theme.textColor};
  font-size: 14px;
  line-height: 17px;
  //max-width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  align-self: center;
`;

const CalanderDiv = styled.a`
  height: 24px;
  width: 29px;
  border: 1px solid ${props => props.theme.noAccent.iconColor};
  border-radius: 4px;
  background-color: ${props => props.theme.secondaryContentBackgroundColor};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.noAccent.textColor};
`;

const InfoDiv = styled.div`
  margin-left: 0.5em;
  display: flex;
  align-items: center;
  //width: 220px;
  justify-content: flex-end;
  overflow: hidden;
  flex: 0 0 500px;
`;

const AvatarDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  //overflow: hidden;
  //white-space: nowrap;
  //text-overflow: ellipsis;
  color: rgba(51, 51, 51, 0.7);
  font-family: Lato, sans-serif;
  margin-right: 1em;
  //font-size: 12px;
`;

const TaskContainer = styled.div`
  display: flex;
  border-radius: 4px;
  background-color: ${props => props.theme.secondaryContentBackgroundColor};
  opacity: ${(props: {
    isFinished: boolean;
    isHighlighted: boolean;
    isOverdue: boolean;
  }) => (props.isFinished ? '0.5' : '1')};

  ${(props: {
    isFinished: boolean;
    isHighlighted: boolean;
    isOverdue: boolean;
  }) =>
    props.isOverdue && !props.isFinished
      ? 'border: 1px solid rgb(255, 92, 57);'
      : ''}
    
  ${(props: {
    isFinished: boolean;
    isHighlighted: boolean;
    isOverdue: boolean;
  }) => (props.isOverdue && !props.isFinished ? 'font-weight: bold;' : '')}

  box-shadow: ${(props: {
    isFinished: boolean;
    isHighlighted: boolean;
    isOverdue: boolean;
  }) =>
    props.isHighlighted
      ? '0 2px 7px 0 rgba(0, 0, 0, 0.2)'
      : '0 2px 7px 0 rgba(0, 0, 0, 0.1)'};
  height: 38px;
  margin-bottom: 8px;
  box-sizing: border-box;
  padding: 0.5em;

  overflow: hidden;

  overflow-wrap: break-word;
  word-break: break-word; /* Chrome, Safari */
  word-wrap: break-word; /* IE11, Firefox */

  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  transition: box-shadow 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
    //transform: scale(1.01);
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.2);
  }

  page-break-inside: avoid;
`;

const Circle = styled.div`
  height: 1em;
  width: 1em;
  border-radius: 50%;
  margin-right: 0.5em;
  opacity: 0.5;
`;

interface ITaskComp2EventInstance {
  id: string;
  title: string;
  isFavourite: boolean;
  isDone: boolean;
  note: string;
  tags: {
    id: string;
    title: string;
    color?: string;
  }[];
  description: string;

  startDate: Date;
  endDate: Date;
  reminderDate: Date;

  createdBy: FieldsOnMiniPersonFragment;
  responsible: FieldsOnMiniPersonFragment;

  taskId: string;
  taskTitle: string;
  taskCalendarId: string;
  taskCalendarTitle?: string;
}

export interface ITaskComp2Props {
  eventInstance: ITaskComp2EventInstance;
  canWrite: boolean;
  disabled?: boolean;
  style?: CSSProperties;
  checkBoxColor?: string;
  isHighlighted?: boolean;
  isFinishAllTodosClicked?: boolean;
  calendarCode: string;
  me: any;
  onFavouriteClick?: () => void;
  /**
   * this is dispatched after a task is completed successfully (isDone is set to true)
   */
  onCompleted?: (id: number | string, isDone: boolean) => void;

  // stupid temp name...
  onRestored?: (id: number | string, isDone: boolean) => void;

  /**
   * onClick card (open edit task)
   */
  onClick: () => void;

  profileToShow: FieldsOnMiniPersonFragment;
  assignedToMe: boolean;
}

interface ITaskComp2UpdateState {
  isFavourite: boolean;
  isDone: boolean;
  noteText: string;
}

class TaskCompComp2 extends React.PureComponent<
  ITaskComp2Props & InjectedIntlProps,
  ITaskComp2UpdateState
> {
  constructor(props: ITaskComp2Props & InjectedIntlProps) {
    super(props);
    this.state = {
      isFavourite: this.props.eventInstance.isFavourite,
      isDone: this.props.eventInstance.isDone,
      noteText: this.props.eventInstance.note || '',
      // myTodosToSend: [],
      // myInstanceToSend: undefined,
    };
  }

  render() {
    const { intl } = this.props;
    const tags = _.sortBy(this.props.eventInstance.tags, 'title');

    let profilePic: any = undefined;
    if (this.props.profileToShow.picture) {
      profilePic = 'https://www.munikum.no/' + this.props.profileToShow.picture;
    }

    let now = new Date();
    let properEndDate = new Date(this.props.eventInstance.endDate);
    let isOverdue = properEndDate < now;
    let daysRemaining = moment(properEndDate).diff(moment(now), 'days');

    return (
      <Mutation
        mutation={UPDATE_INSTANCE_STATUS}
        optimisticResponse={{
          updateEventInstance: {
            id: this.props.eventInstance.id,
            isDone: this.state.isDone,
            isFavourite: this.state.isFavourite,
            status: ContentStatus.PUBLISHED,
            __typename: 'EventInstance',
          },
        }}
      >
        {(updateInstanceStatus, { data, client }) => {
          return (
            <TaskContainer
              isHighlighted={this.props.isHighlighted || false}
              style={this.props.style}
              isFinished={this.state.isDone}
              isOverdue={isOverdue}
              onClick={() => {
                safeInvoke(this.props.onClick);
              }}
            >
              <TaskDiv>
                <CheckboxLight
                  style={{
                    flex: '0 0 auto',
                  }}
                  color={this.props.checkBoxColor}
                  checked={this.state.isDone}
                  onClick={() => {
                    if (this.props.canWrite) {
                      this.setState(
                        {
                          isDone: !this.state.isDone,
                        },
                        () => {
                          if (!this.props.eventInstance.id) {
                            throw Error('id not defined');
                          }
                          let isDescriptionDraftJsFormat = false;
                          let isNoteDraftJsFormat = false;
                          try {
                            convertFromRaw(
                              JSON.parse(this.props.eventInstance.description)
                            ).getPlainText();
                            isDescriptionDraftJsFormat = true;
                          } catch (e) {
                            isDescriptionDraftJsFormat = false;
                          }
                          try {
                            convertFromRaw(
                              JSON.parse(this.props.eventInstance.note)
                            ).getPlainText();
                            isNoteDraftJsFormat = true;
                          } catch (e) {
                            isNoteDraftJsFormat = false;
                          }

                          const values: UpdateEventInstanceInput = {
                            id: this.props.eventInstance.id,
                            title: this.props.eventInstance.title,
                            description: this.props.eventInstance.description,
                            descriptionText: this.props.eventInstance
                              .description
                              ? isDescriptionDraftJsFormat
                                ? convertFromRaw(
                                    JSON.parse(
                                      this.props.eventInstance.description
                                    )
                                  ).getPlainText()
                                : ''
                              : this.props.eventInstance.description,
                            startDate: this.props.eventInstance.startDate,
                            endDate:
                              this.props.eventInstance.endDate ||
                              this.props.eventInstance.startDate,
                            status: ContentStatus.PUBLISHED,
                            note: this.props.eventInstance.note,
                            noteText: this.props.eventInstance.note
                              ? isNoteDraftJsFormat
                                ? convertFromRaw(
                                    JSON.parse(this.props.eventInstance.note)
                                  ).getPlainText()
                                : ''
                              : this.props.eventInstance.note,
                            isDone: this.state.isDone,
                            isFavourite: this.state.isFavourite,
                            responsible: this.props.eventInstance.responsible
                              ? this.props.eventInstance.responsible.id
                              : null,
                            reminderDate: this.props.eventInstance.reminderDate,
                          };
                          const temp2: UpdateInstanceStatusVariables = {
                            input: values,
                          };
                          updateInstanceStatus({
                            variables: temp2,
                          }).then(() => {
                            this.state.isDone
                              ? safeInvoke(
                                  this.props.onCompleted,
                                  this.props.eventInstance.id,
                                  true
                                )
                              : safeInvoke(
                                  this.props.onRestored,
                                  this.props.eventInstance.id,
                                  false
                                );
                          });
                        }
                      );
                    } else {
                      alert(intl.formatMessage(commonMessages.noWriteAccess));
                    }
                  }}
                />

                <TaskText
                  style={{
                    marginLeft: '.5em',
                  }}
                >
                  {this.state.isDone && (
                    <Tooltip
                      usePortal={true}
                      style={{
                        fontSize: '16px',
                      }}
                      content={this.props.eventInstance.title}
                      position={'top-start'}
                    >
                      <del
                        style={{
                          textDecoration: 'line-through',
                        }}
                      >
                        {this.props.eventInstance.title}
                      </del>
                    </Tooltip>
                  )}

                  {!this.state.isDone && (
                    <Tooltip
                      usePortal={true}
                      style={{
                        fontSize: '16px',
                      }}
                      content={this.props.eventInstance.title}
                      position={'top-start'}
                    >
                      <div
                        style={{
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {this.props.eventInstance.title}
                      </div>
                    </Tooltip>
                  )}
                  {/*<Border isFinished={this.state.isDone} />*/}
                </TaskText>
                <TaskText style={{ paddingLeft: '5px' }}>
                  ({this.props.eventInstance.taskCalendarTitle})
                </TaskText>
              </TaskDiv>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {tags &&
                  tags.map((c: any, i) => {
                    if (c.color !== '' || undefined) {
                      return (
                        <Tooltip key={c.title + i} content={c.title}>
                          <Circle
                            key={i}
                            style={{
                              backgroundColor: c?.color ?? Colors.GREY,
                            }}
                          />
                        </Tooltip>
                      );
                    } else {
                      return (
                        <Tooltip content={c.title}>
                          <Circle
                            key={i}
                            style={{ backgroundColor: Colors.GREY }}
                          />
                        </Tooltip>
                      );
                    }
                  })}
              </div>
              <InfoDiv>
                {this.props.profileToShow.id !== this.props.me.id ? (
                  <>
                    <AssignedToDiv>
                      {this.props.assignedToMe ? (
                        <span
                          style={{
                            width: '90%',
                            textAlign: 'right',
                            fontWeight: 'bold',
                          }}
                        >
                          Tildelt av:
                        </span>
                      ) : (
                        <span
                          style={{
                            width: '90%',
                            textAlign: 'right',
                            fontWeight: 'bold',
                          }}
                        >
                          Tildelt til:
                        </span>
                      )}
                    </AssignedToDiv>
                    <AvatarDiv>
                      <Tooltip
                        usePortal={true}
                        content={
                          <UserPreview
                            organizationImage={
                              this.props.profileToShow?.organization
                                ?.organizationImage
                            }
                            image={profilePic}
                            name={this.props.profileToShow?.name}
                            organizationName={
                              this.props.profileToShow?.organization?.name
                            }
                            jobTitle={this.props.profileToShow?.role}
                            id={this.props.profileToShow.id}
                            organizationUrl={
                              this.props.profileToShow?.organization?.url
                            }
                          />
                        }
                      >
                        <div
                          style={{
                            display: 'inline-block',
                          }}
                        >
                          {profilePic && (
                            <img
                              // onClick={(e)=> {
                              //   e.stopPropagation();
                              //   myHistory.push('/person/' +
                              //     this.props.eventInstance?.responsible?.id ??
                              //     this.props.eventInstance?.createdBy?.id);
                              // }}
                              alt={this.props.profileToShow.name}
                              style={{
                                borderRadius: '50%',
                              }}
                              src={profilePic}
                              width="25px"
                              height="25px"
                            />
                          )}
                          {!profilePic && (
                            <Avatar
                              // onClick={(e: any)=> {
                              //   e.stopPropagation();
                              //   myHistory.push('/person/' +
                              //     this.props.eventInstance?.responsible?.id ??
                              //     this.props.eventInstance?.createdBy?.id);
                              // }}
                              name={this.props.profileToShow.name}
                              style={{ marginRight: '.2em' }}
                            />
                          )}
                        </div>
                      </Tooltip>
                    </AvatarDiv>
                  </>
                ) : (
                  <span />
                )}

                <TaskDate>
                  {this.props.eventInstance.startDate ===
                  this.props.eventInstance.endDate ? (
                    <div
                      style={{
                        alignItems: 'center',
                        display: 'flex',
                      }}
                    >
                      <div
                        style={{
                          fontWeight: 'bold',
                          width: '2.7em',
                          marginRight: '1em',
                        }}
                      >
                        {intl.formatMessage(commonLabels.endDate)}:
                      </div>
                      {moment(this.props.eventInstance.endDate).format(
                        'ddd Do MMM YYYY'
                      )}
                    </div>
                  ) : (
                    <>
                      <div
                        style={{
                          alignItems: 'center',
                          display: 'flex',
                        }}
                      >
                        <div
                          style={{
                            fontWeight: 'bold',
                            width: '2.7em',
                            marginRight: '1em',
                          }}
                        >
                          {intl.formatMessage(commonLabels.startDate)}:
                        </div>
                        {moment(this.props.eventInstance.startDate).format(
                          'ddd Do MMM YYYY'
                        )}
                      </div>
                      <div
                        style={{
                          alignItems: 'center',
                          display: 'flex',
                        }}
                      >
                        <div
                          style={{
                            fontWeight: 'bold',
                            width: '2.7em',
                            marginRight: '1em',
                          }}
                        >
                          {intl.formatMessage(commonLabels.endDate)}:
                        </div>
                        <div>
                          {moment(this.props.eventInstance.endDate).format(
                            'ddd Do MMM YYYY'
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </TaskDate>
                <DaysRemainingDiv>
                  {daysRemaining === 0 ? (
                    <>Under 1 dag!</>
                  ) : isOverdue ? (
                    <>{-daysRemaining} dagar over</>
                  ) : (
                    <>{daysRemaining} dagar igjen</>
                  )}
                </DaysRemainingDiv>
                <Tooltip usePortal={true} content={'Legg til i Outlook'}>
                  <div
                    style={{
                      display: 'inline-block',
                    }}
                  >
                    <CalanderDiv
                      onClick={(e: any) => e.stopPropagation()}
                      href={
                        process.env.REACT_APP_API +
                        '/meta/calendar?calendarCode=' +
                        this.props.calendarCode +
                        '&metaId=' +
                        this.props.eventInstance.id
                      }
                    >
                      <MunikumIcons.YearWheel />
                    </CalanderDiv>
                  </div>
                </Tooltip>
              </InfoDiv>
            </TaskContainer>
          );
        }}
      </Mutation>
    );
  }
}

export const TaskComp2 = injectIntl(TaskCompComp2);
