import * as _ from 'lodash';
import { InjectedIntlProps } from 'react-intl';
import * as React from 'react';
import { CSSProperties } from 'react';
import gql from 'graphql-tag';
import Query, { QueryResult } from 'react-apollo/Query';
import styled from 'styled-components';
import { commonMessages } from '../language/commonMessages';
import { myHistory } from '../../index';
import {
  Colors,
  isFunction,
  IThemeProps,
  safeInvokeDeprecated,
} from '../common';
import { MunikumKeys } from '../common/keys';
import {
  OmniSearchQueryQuery,
  OmniSearchQueryVariables,
} from '../../models/types';
import { TextField } from '../basic';
import { Tag } from '../basic/Tag/Tag';
import { MunikumIcons } from '../common/icons';
import {
  IStaticImageType,
  StaticImage,
} from '../basic/StaticImage/StaticImage';
import { Avatar } from '../person/Avatar';

interface IOmniSearchProps {
  onCancel?: () => void;
  onSaveSuccess?: () => void;
  close: () => void;
  style?: CSSProperties;
}

interface IOmniSearchState {
  query: string;
  selectedIndex: number;
  list: Array<ISearchItem>;
}

interface ISearchItem {
  id: string;
  title: string;
  description: string;
  uri: string;
  type: string;
  image?: string;
  title2?: string;
  image2?: string;
  uri2?: string;
  title3?: string;
  image3?: string;
  uri3?: string;
}

const SearchDiv = styled.div`
  overflow: hidden;
  box-sizing: border-box;
  padding: 2em;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  flex: 1 1 100%;
  display: flex;
  border: 1px solid rgba(208, 211, 212, 0.2);
  flex-direction: column;
  background-color: ${props => props.theme.contentBackgroundColor};
`;

const SearchResultDiv = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  background-color: ${props => props.theme.contentBackgroundColor};
  height: 100%;
`;

const SearchItemDiv = styled.div`
  cursor: pointer;
  height: 33px;
  border: 1px solid
    ${(props: { isActive: boolean; theme: IThemeProps }) =>
      props.theme.noAccent.borderColor};
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-top: 10px;
  &:hover {
    text-decoration: none;
  }

  background-color: ${(props: { isActive: boolean; theme: IThemeProps }) =>
    props.isActive
      ? props.theme.tertiaryContentBackgroundColor
      : props.theme.contentBackgroundColor};
`;

const SearchItemTitle = styled.span`
  padding-left: 10px;
  word-break: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 30em;
`;

const SEARCH_QUERY = gql`
  query OmniSearchQuery($query: String!) {
    search(query: $query) {
      persons {
        id
        name
        department
        picture
        role
        organization {
          id
          name
          organizationImage
        }
      }
      organizations {
        id
        name
        organizationImage
        url
      }
      actionValues {
        id
        title
        description
        uri
      }
      discussions {
        id
        title
        description
        uri
        createdBy {
          id
          name
          picture
          organization {
            id
            name
            organizationImage
            url
          }
        }
      }
      topics {
        id
        title
        description
        uri
        createdBy {
          id
          name
          picture
          organization {
            id
            name
            organizationImage
            url
          }
        }
      }
      calendars {
        id
        title
        description
        uri
        createdBy {
          id
          name
          picture
          organization {
            id
            name
            organizationImage
            url
          }
        }
      }
    }
  }
`;

class SearchQuery extends Query<
  OmniSearchQueryQuery,
  OmniSearchQueryVariables
> {}

export class OmniSearchComp extends React.PureComponent<
  IOmniSearchProps & InjectedIntlProps,
  IOmniSearchState
> {
  private myInputRef: any | null;
  // private myInputRef: RefHandler; // old style..
  // private myInputRef?: (instance: any) => void;

  constructor(props: IOmniSearchProps & InjectedIntlProps) {
    super(props);
    this.state = {
      query: '',
      selectedIndex: 0,
      list: [],
    };

    this.myInputRef = null;

    // this.myInputRef = React.createRef();
  }

  handleItemClick = (url: string) => {
    myHistory.push(url);
    safeInvokeDeprecated(this.props.close);
  };

  protected handleKeyUp = (e: any) => {
    if (e.which === MunikumKeys.ARROW_UP) {
      if (this.state.selectedIndex > 0) {
        this.setState({
          selectedIndex: this.state.selectedIndex - 1,
        });
      }
    } else if (e.which === MunikumKeys.ARROW_DOWN) {
      if (this.state.selectedIndex < this.state.list.length - 1) {
        this.setState({
          selectedIndex: this.state.selectedIndex + 1,
        });
      }
    }
  };

  componentDidMount() {
    // console.log('i MOUNTED');

    document.addEventListener('keyup', this.handleKeyUp);
    if (this.myInputRef && isFunction(this.myInputRef.focus)) {
      // console.log('focus that ..');
      this.myInputRef.focus();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.handleKeyUp);
  }
  //
  // shouldComponentUpdate(nextProps: any, nextState: any) {
  //   return true;
  // }

  handleQueryChange = (query: string) => {
    this.setState({
      query: query,
      selectedIndex: -1,
    });
  };

  public focus = () => {
    // console.log('FOCUS in omni!');
    if (this.myInputRef) {
      this.myInputRef.focus();
    }
  };

  render() {
    const { intl } = this.props;
    return (
      <SearchDiv style={this.props.style}>
        Tips: Bruk piltastane og enter for å velje!
        <TextField
          // leftIcon={MunikumIcons.Search}

          innerRef={ref => (this.myInputRef = ref)}
          // ref={this.myInputRef}
          name={'query'}
          value={this.state.query}
          onKeyDown={(e: any) => {
            if (
              e.which === MunikumKeys.ARROW_UP ||
              e.which === MunikumKeys.ARROW_DOWN
            ) {
              e.preventDefault();
              e.stopPropagation();
            }

            if (e.which === MunikumKeys.ENTER) {
              if (this.state.list[this.state.selectedIndex]) {
                this.handleItemClick(
                  this.state.list[this.state.selectedIndex].uri
                );
              }
            }
          }}
          onChange={(e: any) => this.handleQueryChange(e.target.value)}
          style={{ width: '100%', height: '50px' }}
          placeholder={'Søk...'}
        />
        {this.state.query.length > 0 && (
          <SearchQuery
            fetchPolicy={'cache-and-network'}
            query={SEARCH_QUERY}
            variables={{ query: this.state.query }}
            onCompleted={(data: OmniSearchQueryQuery) => {
              let list: Array<ISearchItem> = [];

              data.search.persons.forEach((item, i) => {
                list.push({
                  id: item.id,
                  title: item.name,
                  uri: '/person/' + item.id,
                  description: '',
                  type: 'PERSON',
                  image: item.picture,
                  title2:
                    item &&
                    item.organization &&
                    item.organization.name &&
                    item.organization.name,
                  image2:
                    item &&
                    item.organization &&
                    item.organization.organizationImage &&
                    item.organization.organizationImage,
                });
              });

              data.search.organizations.forEach((item, i) => {
                list.push({
                  id: item.id,
                  title: item.name,
                  uri: '/organization/' + item.url,
                  description: '',
                  type: 'ORGANIZATION',
                  image: item.organizationImage,
                });
              });

              data.search.actionValues.forEach((item, i) => {
                list.push({
                  id: item.id,
                  title: item.title,
                  uri: '/action-value/' + item.uri,
                  description: item.description || '',
                  type: 'ACTION_VALUE',
                });
              });

              data.search.discussions.forEach((item, i) => {
                list.push({
                  id: item.id,
                  title: item.title,
                  uri: '/forum/public/' + item.uri,
                  description: item.description || '',
                  type: 'DISCUSSION',
                  title2: item.createdBy.name,
                  image2: item.createdBy.picture,
                  uri2: '/person/' + item.createdBy.id,
                  title3: item.createdBy.organization
                    ? item.createdBy.organization.name
                    : '',
                  image3: item.createdBy.organization
                    ? item.createdBy.organization.organizationImage
                    : '',
                  uri3: item.createdBy.organization
                    ? '/organization/' + item.createdBy.organization.url
                    : '',
                });
              });

              data.search.topics.forEach((item, i) => {
                list.push({
                  id: item.id,
                  title: item.title,
                  uri: '/topic/' + item.uri,
                  description: item.description || '',
                  type: 'TOPIC',
                  title2: item.createdBy.name,
                  image2: item.createdBy.picture,
                  uri2: '/person/' + item.createdBy.id,
                  title3: item.createdBy.organization
                    ? item.createdBy.organization.name
                    : '',
                  image3: item.createdBy.organization
                    ? item.createdBy.organization.organizationImage
                    : '',
                  uri3:
                    '/organization/' + item.createdBy.organization
                      ? item.createdBy.organization.url
                      : '',
                });
              });

              data.search.calendars.forEach((item, i) => {
                list.push({
                  id: item.id,
                  title: item.title,
                  uri: '/calendar/' + item.uri,
                  description: item.description || '',
                  type: 'CALENDAR',
                  title2: item.createdBy.name,
                  image2: item.createdBy.picture,
                  uri2: '/person/' + item.createdBy.id,
                  title3: item.createdBy.organization
                    ? item.createdBy.organization.name
                    : '',
                  image3: item.createdBy.organization
                    ? item.createdBy.organization.organizationImage
                    : '',
                  uri3:
                    '/organization/' + item.createdBy.organization
                      ? item.createdBy.organization.url
                      : '',
                });
              });

              if (!_.isEqual(list, this.state.list)) {
                this.setState({ list: list });
              }
            }}
            children={(
              result: QueryResult<
                OmniSearchQueryQuery,
                OmniSearchQueryVariables
              >
            ) => {
              if (result.loading) {
                return (
                  <div>{intl.formatMessage(commonMessages.searching)}</div>
                );
              }

              if (result.error) {
                return <div>Error</div>;
              }

              if (this.state.list.length > 0) {
                return (
                  <SearchResultDiv>
                    {/*{data.search.persons && data.search.persons.map(person => {*/}
                    {/*return <SearchItemDiv key={person.id}>*/}
                    {/*{person.name}*/}
                    {/*</SearchItemDiv>;*/}
                    {/*})}*/}
                    {this.state.list.map((item, i) => {
                      const isActive = this.state.selectedIndex === i;

                      return (
                        <SearchItemDiv
                          onMouseEnter={() => {
                            this.setState({
                              selectedIndex: i,
                            });
                          }}
                          onMouseLeave={() => {
                            this.setState({
                              selectedIndex: -1,
                            });
                          }}
                          onClick={e => this.handleItemClick(item.uri)}
                          // style={{
                          //   backgroundColor: isActive
                          //     ? 'rgba(208, 211, 212, 0.7)'
                          //     : '#fff',
                          // }}
                          isActive={isActive}
                          key={item.id}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            {(item.type === 'ACTION_VALUE' && (
                              <Tag
                                leftIconColor={'#fff'}
                                leftIcon={MunikumIcons.ActionValue}
                                hasShadow={false}
                                text={intl.formatMessage(
                                  commonMessages.actionValues
                                )}
                                color={Colors.DARKYELLOW}
                                uppercase={false}
                                style={{ height: '28px', width: '112px' }}
                              />
                            )) ||
                              (item.type === 'DISCUSSION' && (
                                <Tag
                                  leftIconColor={'#fff'}
                                  leftIcon={MunikumIcons.Discussion}
                                  hasShadow={false}
                                  text={intl.formatMessage(
                                    commonMessages.forum
                                  )}
                                  color={Colors.RED}
                                  uppercase={false}
                                  style={{ height: '28px', width: '112px' }}
                                />
                              )) ||
                              (item.type === 'TOPIC' && (
                                <Tag
                                  leftIconColor={'#fff'}
                                  leftIcon={MunikumIcons.Topic}
                                  hasShadow={false}
                                  text={intl.formatMessage(
                                    commonMessages.topic
                                  )}
                                  color={Colors.DARKBLUE}
                                  uppercase={false}
                                  style={{ height: '28px', width: '112px' }}
                                />
                              )) ||
                              (item.type === 'CALENDAR' && (
                                <Tag
                                  leftIconColor={'#fff'}
                                  leftIcon={MunikumIcons.Circle1}
                                  hasShadow={false}
                                  text={intl.formatMessage(
                                    commonMessages.calendars
                                  )}
                                  color={Colors.DARKRED}
                                  uppercase={false}
                                  style={{ height: '28px', width: '112px' }}
                                />
                              )) ||
                              (item.type === 'PERSON' &&
                                ((item.image && (
                                  <img
                                    width={21}
                                    height={21}
                                    src={'https://www.munikum.no/' + item.image}
                                    alt={''}
                                  />
                                )) || (
                                  <Avatar
                                    style={{ width: '28px', height: '23px' }}
                                    name={item.title}
                                  />
                                ))) ||
                              (item.type === 'ORGANIZATION' && item.image && (
                                <StaticImage
                                  key={i}
                                  width={21}
                                  height={21}
                                  type={IStaticImageType.ORGANIZATION}
                                  filename={item.image}
                                />
                              ))}
                            <SearchItemTitle>{item.title}</SearchItemTitle>
                          </div>
                          <div>
                            {(item.type === 'DISCUSSION' ||
                              item.type === 'TOPIC' ||
                              item.type === 'CALENDAR') &&
                              item.title2 && (
                                <div
                                  style={{
                                    display: 'flex',
                                    paddingLeft: '1em',
                                    paddingRight: '1em',
                                    textAlign: 'left',
                                    justifyContent: 'flex-start',
                                    width: '16.250em',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                  }}
                                >
                                  {(item.image2 && (
                                    <img
                                      width={21}
                                      height={21}
                                      src={
                                        'https://www.munikum.no/' + item.image2
                                      }
                                      alt={''}
                                    />
                                  )) || <Avatar name={item.title2} />}
                                  <span style={{ paddingLeft: '0.5em' }}>
                                    {item.title2}
                                  </span>
                                </div>
                              )}
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'flex-start',
                            }}
                          >
                            {(item.type === 'DISCUSSION' ||
                              item.type === 'TOPIC' ||
                              item.type === 'CALENDAR') &&
                              item.title3 &&
                              item.image3 && (
                                <div
                                  style={{
                                    paddingLeft: '1em',
                                    paddingRight: '1em',
                                    display: 'flex',
                                    width: '16.250em',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                  }}
                                >
                                  <StaticImage
                                    key={i}
                                    width={20}
                                    height={20}
                                    type={IStaticImageType.ORGANIZATION}
                                    filename={item.image3}
                                  />
                                  <span style={{ paddingLeft: '0.5em' }}>
                                    {item.title3}
                                  </span>
                                </div>
                              )}
                            {item.type === 'PERSON' &&
                              item.title2 &&
                              item.image2 && (
                                <div
                                  style={{
                                    paddingLeft: '1em',
                                    paddingRight: '1em',
                                    display: 'flex',
                                    width: '16.250em',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                  }}
                                >
                                  <StaticImage
                                    key={i}
                                    width={20}
                                    height={20}
                                    type={IStaticImageType.ORGANIZATION}
                                    filename={item.image2}
                                  />
                                  <span style={{ paddingLeft: '0.5em' }}>
                                    {item.title2}
                                  </span>
                                </div>
                              )}
                            <MunikumIcons.ArrowRight
                              style={{ marginRight: '1em', color: Colors.RED }}
                            />
                          </div>
                        </SearchItemDiv>
                      );
                    })}
                  </SearchResultDiv>
                );
              } else {
                return <div>{intl.formatMessage(commonMessages.noResult)}</div>;
              }
            }}
          />
        )}
      </SearchDiv>
    );
  }
}

// export const OmniSearch = injectIntl(OmniSearchComp, { withRef: true });
